.flex{display:flex}.flex.align-items-center{align-items:center}.flex.align-items-baseline{align-items:baseline}.flex.wrap{flex-wrap:wrap}.flex.column{flex-direction:column}.flex.justify-content-center{justify-content:center}.flex.space-between{justify-content:space-between}.flex.space-around{justify-content:space-around}.flex.stretch{align-items:stretch}.flex.justify-content-start{justify-content:flex-start}.position-relative{position:relative}.position-absolute{position:absolute}.hidden{display:none !important}.pointer{cursor:pointer}.spring{flex:1 1;min-width:0}.full-height{height:100%}.full-width{width:100%}.margin-0{margin:0}.bottom-0{bottom:0}.margin-bottom-8{margin-bottom:8px}.margin-bottom-16{margin-bottom:16px}.margin-top-16{margin-top:16px}.padding-bottom-8{padding-bottom:8px}.padding-bottom-16{padding-bottom:16px}.margin-top-32{margin-top:32px}.margin-bottom-32{margin-bottom:32px}.color-white{color:#ffffff}body{margin:0;font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;outline:none}code{font-family:source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace}a{text-decoration:none;color:inherit}

.app{height:100vh}

.update-user-attribute .update-attribute-content{width:460px;padding:40px;border-radius:6px;box-shadow:1px 1px 4px 0 rgba(0,0,0,0.15)}.update-user-attribute .form-wrapper .attribute-field{margin-bottom:16px}.update-user-attribute .form-wrapper .attribute-header{margin-bottom:16px}

.navbar .logo{height:65px}


.chat-page{border:1px solid rgba(0,0,0,0.12);border-radius:4px}.chat-page .header{padding:16px;background-color:#ffffff;margin:0}

.video-player{position:relative}.video-player .video-wrapper{flex:1 1;height:100%}.video-player .video-expand-toggle{padding:30px 4px;position:absolute;right:0;top:44%;color:#fff;background-color:rgba(0,0,0,0.32)}.video-player .tip-btn{position:absolute;bottom:32px;right:0;padding:6px 14px;border-radius:4px;border:1px solid #fff;color:#fff;background-color:rgba(0,0,0,0.32)}

.stripe-payment-form{min-width:500px}.stripe-payment-form .StripeElement{padding:16px 8px;border:1px solid;border-radius:4px}.stripe-payment-form .strip-form-field-item{margin-bottom:16px}.stripe-payment-form .payment-modal-footer{border-top:1px solid rgba(0,0,0,0.12);margin:0px -16px -16px;padding:8px 0}



.settings-form .form-item{margin-bottom:4px}.settings-form .form-item input{text-align:center;border:1px solid rgba(0,0,0,0.12);border-radius:2px;width:200px;padding-left:4px}.settings-form .form-item .secret-key-wrapper .actions{margin-right:-165px}.settings-form .MuiInput-underline:before{content:none}.settings-form .checkbox-wrapper{width:200px}


.update-password .update-password-content{width:460px;padding:40px;border-radius:6px;box-shadow:1px 1px 4px 0 rgba(0,0,0,0.15)}.update-password .form-wrapper .old-password{margin-bottom:16px}.update-password .form-wrapper .password-header{margin-bottom:16px}

