.flex {
  display: flex;
  &.align-items-center {
    align-items: center;
  }
  &.align-items-baseline {
    align-items: baseline;
  }
  &.wrap {
    flex-wrap: wrap;
  }

  &.column {
    flex-direction: column;
  }
  &.justify-content-center {
    justify-content: center;
  }
  &.space-between {
    justify-content: space-between;
  }
  &.space-around {
    justify-content: space-around;
  }
  &.stretch {
    align-items: stretch;
  }
  &.justify-content-start {
    justify-content: flex-start;
  }
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.hidden {
  display: none !important;
}

.pointer {
  cursor: pointer;
}

.spring {
  flex: 1;
  min-width: 0;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}
