.margin-0 {
  margin: 0;
}

.bottom-0 {
  bottom: 0;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

.margin-top-16 {
  margin-top: 16px;
}

.padding-bottom-8 {
  padding-bottom: 8px;
}

.padding-bottom-16 {
  padding-bottom: 16px;
}
.margin-top-32 {
  margin-top: 32px;
}
.margin-bottom-32 {
  margin-bottom: 32px;
}
