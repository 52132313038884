.update-password {
  .update-password-content {
    width: 460px;
    padding: 40px;
    border-radius: 6px;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  }
  .form-wrapper {
    .old-password {
      margin-bottom: 16px;
    }
    .password-header {
      margin-bottom: 16px;
    }
  }
}
