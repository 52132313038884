.chat-page {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;

  .header {
    padding: 16px;
    background-color: #ffffff;
    margin: 0;
  }
}
